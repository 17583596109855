import { ethers } from "ethers";

const Footer = ({ gscBalance, gsdBalance }) => {
  return (
    <footer>
      <nav className="navbar fixed-bottom navbar-light bg-light">
        <div className="container-fluid">
          <span>
            GSC Balance: {gscBalance && ethers.utils.formatUnits(gscBalance)}
          </span>
          <span>
            GSD Balance: {gsdBalance && ethers.utils.formatUnits(gsdBalance)}
          </span>
          <a
            href="https://kovan.etherscan.io/address/0xFcf2F9323240F20569e3D2D8Be908b3a443c33D6"
            target="_blank"
            rel="noreferrer"
          >
            Contract Address
          </a>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
