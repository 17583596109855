import GotchiHome from "../components/GotchiHome";
import { Link } from "react-router-dom";
import Login from "../components/Login";

const Home = ({
  gotchiOwnerList,
  gotchiPoolList,
  isApproved,
  gscAllowance,
  approveGsc,
  setApprovalForAll,
  depositGotchi,
  withdrawGotchi,
  onboard,
  signer,
  connected,
  setConnected,
  gscBalance,
}) => {
  return (
    <div className="wrapper">
      <section className="dotchiswap-finance-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dotchiswap-finance-top">
                <div className="text-box">
                  <h3>
                    Gotchiswap.finance: tokenize and trade your aavegotchis
                  </h3>
                  <p>
                    Tokenize your Aavergotchi, get gotchi tokens in exchange.
                    With gotchi tokens you can buy other gotchis in the pool, or
                    you can exchange them on Sushiswap
                  </p>
                </div>
              </div>
              <div className="finance-btn">
                <ul>
                  <li>
                    <Link to="/buy">
                      <span>Buy</span>
                      aavegotchi - token
                    </Link>
                  </li>
                  <li>
                    <a
                      className="green-bg"
                      href="https://app.sushi.com/add/0xcE1917646E2cECf158F5A4bB7900aFE2EA4Af600/0xeDaA788Ee96a0749a2De48738f5dF0AA88E99ab5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Buy or Sell tokens</span>
                      aavegotchi - token
                    </a>
                  </li>
                  <li>
                    <Link to="/sell">
                      <span>Sell</span>
                      token - aavegotchi
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dotchiswap-finance-bottom">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="dotchiswap-box">
                      <div className="text-box">
                        <h3>Your Eligible Gotchis</h3>
                        <div className="dotchiswap-table">
                          <div className="table-responsive">
                            {!connected && (
                              <>
                                <p>
                                  Connect your wallet to see your eligible
                                  gotchis
                                </p>
                                <Login
                                  onboard={onboard}
                                  signer={signer}
                                  connected={connected}
                                  setConnected={setConnected}
                                />
                              </>
                            )}
                            <table>
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>ID</th>
                                  <th>BRS</th>
                                  <th>Value</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {gotchiOwnerList?.length > 0 ? (
                                  gotchiOwnerList.map((gotchi) => (
                                    <GotchiHome
                                      key={gotchi.tokenId}
                                      gotchi={gotchi}
                                      dir={"buy"}
                                      isApproved={isApproved}
                                      setApprovalForAll={setApprovalForAll}
                                      depositGotchi={depositGotchi}
                                      gscBalance={gscBalance}
                                    />
                                  ))
                                ) : connected ? (
                                  <tr>
                                    {/* <td> */}
                                    <span>
                                      You don't have any eligible aavegotchi{" "}
                                    </span>
                                    <a
                                      href="https://testnet.aavegotchi.com/buy-portal"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Get Aavegotchis
                                    </a>
                                    {/* </td> */}
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="dotchiswap-box available-gotchis">
                      <div className="text-box">
                        <h3>Available Gotchis </h3>
                        <div className="dotchiswap-table">
                          <div className="table-responsive">
                            {!connected && (
                              <>
                                <p>
                                  Connect your wallet to see the availables
                                  gotchis
                                </p>
                                <Login
                                  onboard={onboard}
                                  signer={signer}
                                  connected={connected}
                                  setConnected={setConnected}
                                />
                              </>
                            )}
                            <table>
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>ID</th>
                                  <th>BRS</th>
                                  <th>Value</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {gotchiPoolList &&
                                  gotchiPoolList.map((gotchi, gotchiIndex) => (
                                    <GotchiHome
                                      key={gotchi.tokenId}
                                      gotchi={gotchi}
                                      dir={"sell"}
                                      gscAllowance={gscAllowance}
                                      approveGsc={approveGsc}
                                      withdrawGotchi={withdrawGotchi}
                                      gotchiIndex={gotchiIndex}
                                      gscBalance={gscBalance}
                                    />
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
