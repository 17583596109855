import Gotchi from "../components/Gotchi";
import Login from "../components/Login";

const Buy = ({
  gotchiPoolList,
  gscAllowance,
  approveGsc,
  withdrawGotchi,
  connected,
  setConnected,
  onboard,
  signer,
  gscBalance,
}) => {
  return (
    <section className="sell-sec">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="sell-inner">
              {/* <div className="sell-search">
                <form>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <a href="#">
                    <img src="images/search-icon.svg" alt="" />
                  </a>
                </form>
              </div> */}
              <div className="sell-filter">
                {/* <div className="sell-filter-inner dropdown">
                  <a
                    href="#"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="images/filter-icon.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div className="dropdown-menu-inner">
                      <div className="filter-box rubber-ipt">
                        <div className="title">
                          <h4>AGG</h4>
                          <div className="value-text">
                            <p className="rubber-value-min">10</p>
                            <span>-</span>
                            <p className="rubber-value-max">1000</p>
                          </div>
                        </div>
                        <div className="price-range-block">
                          <div className="rubber-ipt-inner">
                            <div className="rubber-ipt-range"></div>

                            <div className="rubber-ipt-min"></div>
                            <div className="rubber-ipt-max"></div>
                          </div>
                        </div>
                      </div>
                      <div className="filter-box rubber-ipt">
                        <div className="title">
                          <h4>SPK</h4>
                          <div className="value-text">
                            <p className="rubber-value-min">10</p>
                            <span>-</span>
                            <p className="rubber-value-max">1000</p>
                          </div>
                        </div>
                        <div className="price-range-block">
                          <div className="rubber-ipt-inner">
                            <div className="rubber-ipt-range"></div>
                            <div className="rubber-ipt-min"></div>
                            <div className="rubber-ipt-max"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="dotchiswap-box available-gotchis">
                  <div className="text-box">
                    <h3>Available Gotchis</h3>
                    <div className="dotchiswap-table">
                      <div className="table-responsive">
                        {!connected && (
                          <>
                            <p>
                              Connect your wallet to see the availables gotchis
                            </p>
                            <Login
                              onboard={onboard}
                              signer={signer}
                              connected={connected}
                              setConnected={setConnected}
                            />
                          </>
                        )}
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>
                                ID{" "}
                                <img src="images/sort-arrow-icon.svg" alt="" />
                              </th>
                              <th>
                                BRS{" "}
                                <img src="images/sort-arrow-icon.svg" alt="" />
                              </th>
                              <th>NRG</th>
                              <th>AGG</th>
                              <th>SPK</th>
                              <th>BRN</th>
                              <th>EYS</th>
                              <th>EYC</th>
                              <th>
                                Price{" "}
                                <img src="images/sort-arrow-icon.svg" alt="" />
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {gotchiPoolList &&
                              gotchiPoolList.map((gotchi, gotchiIndex) => (
                                <Gotchi
                                  key={gotchi.tokenId}
                                  gotchi={gotchi}
                                  dir={"buy"}
                                  gscAllowance={gscAllowance}
                                  approveGsc={approveGsc}
                                  withdrawGotchi={withdrawGotchi}
                                  gotchiIndex={gotchiIndex}
                                  gscBalance={gscBalance}
                                />
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Buy;
