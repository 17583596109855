import { ethers } from "ethers";
// import { Link } from "react-router-dom";

const GotchiHome = ({
  gotchi,
  dir,
  isApproved,
  gscAllowance,
  approveGsc,
  setApprovalForAll,
  depositGotchi,
  withdrawGotchi,
  gotchiIndex,
  gscBalance,
}) => {
  return (
    <>
      {gotchi && (
        <tr>
          <td>
            <span>
              <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  gotchi.svg
                )}`}
                className="img-fluid"
                alt=""
              />
            </span>
          </td>
          <td>{ethers.utils.formatUnits(gotchi.tokenId, 0)}</td>
          <td>{ethers.utils.formatUnits(gotchi.baseRarityScore, 0)}</td>
          <td>{ethers.utils.formatUnits(gotchi.score)}</td>
          <td>
            {dir === "buy" ? (
              isApproved ? (
                <button onClick={() => depositGotchi(gotchi.tokenId)}>
                  Sell
                </button>
              ) : (
                <button onClick={setApprovalForAll}>Approve</button>
              )
            ) : gscBalance >= gotchi.score ? (
              gscAllowance >= gotchi.score ? (
                <button
                  onClick={() => withdrawGotchi(gotchi.tokenId, gotchiIndex)}
                >
                  Buy
                </button>
              ) : (
                <button onClick={approveGsc}>Approve</button>
              )
            ) : (
              <a
                href="https://app.sushi.com/add/0xcE1917646E2cECf158F5A4bB7900aFE2EA4Af600/0xeDaA788Ee96a0749a2De48738f5dF0AA88E99ab5"
                target="_blank"
                rel="noreferrer"
              >
                Get Tokens
              </a>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default GotchiHome;
