import { Link } from "react-router-dom";
import Login from "./Login";

const Header = ({ onboard, signer, connected, setConnected }) => {
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="navigation">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/">
                  <img src="images/logo.svg" alt="" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <Link className="nav-link" to="/buy">
                        Buy
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/sell">
                        Sell
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <a className="nav-link" href="">
                        Doc
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <Login
                        onboard={onboard}
                        signer={signer}
                        connected={connected}
                        setConnected={setConnected}
                      />
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
