// import "./App.css";
import { useState, useEffect } from "react";
import Onboard from "bnc-onboard";
import { ethers } from "ethers";
import Notify from "bnc-notify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Buy from "./views/Buy";
import Sell from "./views/Sell";

const { gotchiswapAbi } = require("./abi/gotchiswapAbi");
const { aavegotchiFacetAbi } = require("./abi/aavegotchiFacetAbi");
const { svgFacetAbi } = require("./abi/svgFacetAbi");
const { erc20Abi } = require("./abi/erc20Abi");

const GOTCHISWAP = "0xFcf2F9323240F20569e3D2D8Be908b3a443c33D6";
const GOTCHIDIAMOND = "0x07543dB60F19b9B48A69a7435B5648b46d4Bb58E";
const GSC = "0xcE1917646E2cECf158F5A4bB7900aFE2EA4Af600";
const GSD = "0x1Ad81e55ba62a460eD185B309Ab0a9b75FD84E91";

function App() {
  const [connected, setConnected] = useState(false);
  const [provider, setProvider] = useState();
  const [notify, setNotify] = useState();
  const [onboard, setOnboard] = useState();
  const [signer, setSigner] = useState();
  const [gotchiswap, setGotchiswap] = useState();
  const [aavegotchiFacet, setAavegotchiFacet] = useState();
  const [svgFacet, setSvgFacet] = useState();
  const [gsc, setGsc] = useState();
  const [gsd, setGsd] = useState();
  const [gscBalance, setGscBalance] = useState();
  const [gscAllowance, setGscAllowance] = useState();
  const [gsdBalance, setGsdBalance] = useState();
  const [gotchiOwnerList, setGotchiOwnerList] = useState();
  const [gotchiPoolList, setGotchiPoolList] = useState();
  const [isApproved, setIsApproved] = useState();

  const getGotchiOwnerList = async () => {
    let list = await aavegotchiFacet.allAavegotchisOfOwner(signer.getAddress());
    let arr = [...list];
    arr = arr.filter((gotchi) => gotchi.name.length > 0);
    for (let i = 0; i < arr.length; i++) {
      let copy = { ...arr[i] };
      const score = await gotchiswap.calcScore(arr[i].tokenId);
      const svg = await svgFacet.getAavegotchiSvg(arr[i].tokenId);
      copy.score = score;
      copy.svg = svg;
      arr[i] = copy;
    }
    setGotchiOwnerList(arr);
  };

  const getGotchiPoolList = async () => {
    let arr = [];
    const list = await gotchiswap.calcList();
    for (let i = 0; i < list.length; i++) {
      const gotchi = await aavegotchiFacet.getAavegotchi(list[i]);
      const score = await gotchiswap.calcScore(gotchi.tokenId);
      const svg = await svgFacet.getAavegotchiSvg(gotchi.tokenId);
      let copy = { ...gotchi };
      copy.score = score;
      copy.svg = svg;
      arr.push(copy);
    }
    setGotchiPoolList(arr);
  };

  const getGscBalance = async () => {
    const balance = await gsc.balanceOf(signer.getAddress());
    setGscBalance(balance);
  };

  const getGsdBalance = async () => {
    const balance = await gsd.balanceOf(signer.getAddress());
    setGsdBalance(balance);
  };

  const setApprovalForAll = async () => {
    const tx = await aavegotchiFacet.setApprovalForAll(GOTCHISWAP, true);
    notify.hash(tx.hash);
    await tx.wait();
  };

  const approveGsc = async () => {
    const tx = await gsc.approve(
      GOTCHISWAP,
      ethers.utils.parseUnits("100000000000")
    );
    notify.hash(tx.hash);
    await tx.wait();
  };

  const getGscAllowance = async () => {
    const allowance = await gsc.allowance(signer.getAddress(), GOTCHISWAP);
    setGscAllowance(allowance);
  };

  const depositGotchi = async (tokenId) => {
    const tx = await gotchiswap.depositGotchi(tokenId);
    notify.hash(tx.hash);
    await tx.wait();
    getGotchiOwnerList();
    getGotchiPoolList();
  };

  const withdrawGotchi = async (tokenId, depositId) => {
    const tx = await gotchiswap.withdrawGotchi(tokenId, depositId);
    notify.hash(tx.hash);
    await tx.wait();
    getGotchiOwnerList();
    getGotchiPoolList();
  };

  const checkApproval = async () => {
    const approved = await aavegotchiFacet.isApprovedForAll(
      signer.getAddress(),
      GOTCHISWAP
    );
    setIsApproved(approved);
  };

  useEffect(() => {
    const initOnboard = Onboard({
      dappId: "11e82408-4791-4664-8d58-f581ac8e95d1", // [String] The API key created by step one above
      networkId: 42, // [Integer] The Ethereum network ID your Dapp uses.
      subscriptions: {
        wallet: (wallet) => {
          const initProvider = new ethers.providers.Web3Provider(
            wallet.provider,
            "any"
          );
          setProvider(initProvider);
        },
      },
      walletSelect: {
        wallets: [{ walletName: "metamask", preferred: true }],
      },
    });
    setOnboard(initOnboard);
  }, []);

  useEffect(() => {
    if (provider?.provider?.networkVersion === "42") {
      setSigner(provider.getSigner());
    }
  }, [provider, connected]);

  useEffect(() => {
    const initNotify = Notify({
      dappId: "11e82408-4791-4664-8d58-f581ac8e95d1", // [String] The API key created by step one above
      networkId: 42, // [Integer] The Ethereum network ID your Dapp uses.
    });
    setNotify(initNotify);
  }, []);

  useEffect(() => {
    if (signer) {
      setGotchiswap(new ethers.Contract(GOTCHISWAP, gotchiswapAbi, signer));
      setAavegotchiFacet(
        new ethers.Contract(GOTCHIDIAMOND, aavegotchiFacetAbi, signer)
      );
      setSvgFacet(new ethers.Contract(GOTCHIDIAMOND, svgFacetAbi, signer));
      setGsc(new ethers.Contract(GSC, erc20Abi, signer));
      setGsd(new ethers.Contract(GSD, erc20Abi, signer));
    }
  }, [signer]);

  useEffect(() => {
    if (aavegotchiFacet) {
      getGotchiOwnerList();
      checkApproval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aavegotchiFacet]);

  useEffect(() => {
    if (gotchiswap) {
      getGotchiPoolList();
      getGscAllowance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gotchiswap]);

  useEffect(() => {
    if (gsc) {
      getGscBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gsc, gotchiOwnerList, gotchiPoolList]);

  useEffect(() => {
    if (gsd) {
      getGsdBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gsd, gotchiOwnerList, gotchiPoolList]);

  return (
    <Router>
      <div>
        <Header
          provider={provider}
          onboard={onboard}
          signer={signer}
          connected={connected}
          setConnected={setConnected}
        />
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/buy">
            <Buy
              gotchiPoolList={gotchiPoolList}
              gscAllowance={gscAllowance}
              approveGsc={approveGsc}
              withdrawGotchi={withdrawGotchi}
              connected={connected}
              onboard={onboard}
              signer={signer}
              setConnected={setConnected}
              gscBalance={gscBalance}
            />
          </Route>
          <Route exact path="/sell">
            <Sell
              gotchiOwnerList={gotchiOwnerList}
              isApproved={isApproved}
              setApprovalForAll={setApprovalForAll}
              depositGotchi={depositGotchi}
              connected={connected}
              onboard={onboard}
              signer={signer}
              setConnected={setConnected}
            />
          </Route>
          <Route exact path="/">
            <Home
              gotchiOwnerList={gotchiOwnerList}
              gotchiPoolList={gotchiPoolList}
              isApproved={isApproved}
              gscAllowance={gscAllowance}
              approveGsc={approveGsc}
              setApprovalForAll={setApprovalForAll}
              depositGotchi={depositGotchi}
              withdrawGotchi={withdrawGotchi}
              connected={connected}
              onboard={onboard}
              signer={signer}
              setConnected={setConnected}
              gscBalance={gscBalance}
            />
          </Route>
        </Switch>
        <Footer gscBalance={gscBalance} gsdBalance={gsdBalance} />
      </div>
    </Router>
  );
}

export default App;
